import React, {useRef} from 'react';
import Theme, {nib, apia, ing, suncorp, aami, priceline, real, australianSeniors as seniors, gu, ModeProvider} from '@nib-components/theme';
import {getThemeBrand, isNibOrDentalBrand} from '../../services/utils';
import ChatLauncherProxy from '../../components/ChatLauncherProxy';

const themes = {
  nib,
  apia,
  ing,
  suncorp,
  aami,
  priceline,
  real,
  seniors,
  guhealth: gu
};

const PageTheme: React.FunctionComponent = ({children}) => {
  const brandString = getThemeBrand();
  const brand = useRef(brandString);

  return (
    <Theme theme={themes[brand.current]}>
      <ModeProvider mode={{nib: 'warm', rest: 'alt'}}>{children}</ModeProvider>
      {isNibOrDentalBrand() && <ChatLauncherProxy />}
    </Theme>
  );
};

export default PageTheme;
